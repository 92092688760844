/** type object node */
export type Query = {
  /** type object node */
  listCateringServices?: CateringService[];
  /** type object node */
  listServiceOptions: (props: {
    cateringServiceID?: string;
  }) => ServiceOption[];
  /** type object node */
  listDeliveries: (props: { cateringServiceID?: string }) => Delivery[];
  /** type object node */
  getDoneDeliveries: (props: {
    cateringServiceID?: string;
    startDateTime?: string;
    endDateTime?: string;
  }) => DoneDelivery[];
};

/** Company contracted with foodeli catering */
export type CateringService = {
  /** name of the company */
  name: string;
  /** ID object node */
  id: string;
};

/** Service option for example 1500kcal, 2500kcal etc */
export type ServiceOption = {
  /** catering service company */
  cateringServiceID: string;
  /** option name */
  option: string;
  /** ID object node */
  id: string;
};

// struct DeliveryCreateData: Content  {
//     let adress: String
//     let dayPart: Int?
//     let days: [Int]
//     let serviceOptionID: UUID
//     let customHour: String?
//     let latitude: Float?
//     let longitde: Float?
// }

// struct DeliveryUpdateData: Content {
//     let adres: String?
//     let dayPart: Int?
//     let days: [Int]?
//     let serviceOptionID: UUID?
//     let customHour: String?
//     let latitude: Float?
//     let longitde: Float?
// }

/** delivery definition, when and which day to wher */
export type Delivery = {
  /** address of the client */
  adress: string;
  /** option to deliver */
  serviceOptionID: string;
  /** part of the day */
  dayPart?: number;
  /** days to deliver */
  days?: number[];
  /** Float object node */
  latitude?: number;
  /** Float object node */
  longitude?: number;
  /** ID object node */
  id: string;
  /** There is possibility to set hour instead of part of the day */
  customHour?: string;
  hourFrom?: string;
  hourTo?: string;
  ref?: string;
  phoneNumber?: string;
  comment?: string;
};

/** part of the day service to be delivered */
export enum DayPart {
  Rano,
  Południe,
  Wieczór
}

/** Weekday */
export enum WeekDay {
  Poniedziałek,
  Wtorek,
  Środa,
  Czwartek,
  Piątek,
  Sobota,
  Niedziela
}
/** Object created when delivery is done */
export type DoneDelivery = {
  /** time of the delivery */
  dateTime: string;
  /** driver */
  user: string;
  /** Photo of the completed delivery ( package left in front of the door ) */
  photoURL?: string;
  returnedPhotoURL?: string;
  /** type object node */
  deliveryID: string;
  /** ID object node */
  id: string;
};
export interface createDoneDelivery {
  /** time of the delivery */
  dateTime: string;
  /** driver */
  user: string;
  /** type object node */
  deliveryID: string;
}

export interface updateDoneDelivery {
  /** time of the delivery */
  dateTime: string;
  /** driver */
  user: string;
  /** type object node */
  deliveryID: string;
  /** ID object node */
  id: string;
}
export interface removeDoneDelivery {
  /** ID object node */
  id: string;
}
/** User is admin or driver for now */
export type User = {
  /** first name of the user */
  firstName: string;
  /** last name of the user */
  lastName: string;
  /** ID object node */
  id: string;
  /** enum object node */
  role?: UserRole;
};

/** enum object node */
export enum UserRole {
  Admin,
  Driver
}

export type Mutation = {
  /** called by driver when delivery is delivered */
  deliver: (props: { delivery?: deliverInput }) => DoneDelivery;
  /** type object node */
  createUser: User;
  /** type object node */
  createDelivery: (props: { delivery?: createDelivery }) => Delivery;
  /** String object node */
  removeDelivery: (props: { delivery?: removeDelivery }) => string;
  /** type object node */
  updateDelivery: (props: { delivery?: updateDelivery }) => Delivery;
  /** type object node */
  createCateringService: (props: {
    cateringService?: createCateringService;
  }) => CateringService;
  /** type object node */
  updateCateringService: (props: {
    cateringService?: updateCateringService;
  }) => CateringService;
  /** String object node */
  removeCateringService: (props: {
    cateringService?: removeCateringService;
  }) => string;
  /** type object node */
  createServiceOption: (props: {
    serviceOption?: createServiceOption;
  }) => ServiceOption;
  /** String object node */
  removeServiceOption: (props: {
    serviceOption?: removeServiceOption;
  }) => string;
  /** type object node */
  createRoute: Route;
};

/** input object node */
export type deliverInput = {
  /** String object node */
  photoURL?: string;
  /** ID object node */
  deliveryID: string;
};

/** input for creation of delivery */
export interface createDelivery {
  /** address of the client */
  adress: string;
  /** part of the day */
  dayPart?: number;
  /** days to deliver */
  days: number[];
  /** ID object node */
  serviceOptionID: string;
  /** String object node */
  customHour?: string;
  hourFrom?: string;
  hourTo?: string;
  ref?: string;
  phoneNumber?: string;
  comment?: string;
}

/** input object node */
export type removeDelivery = {
  /** ID object node */
  id: string;
};

/** input for update of delivery */
export type updateDelivery = {
  /** address of the client */
  address?: string;
  /** part of the day */
  dayPart?: number;
  /** days to deliver */
  days?: number[];
  /** ID object node */
  id: string;
  /** ID object node */
  serviceOptionID?: string;
  /** String object node */
  customHour?: string;
  hourFrom?: string;
  hourTo?: string;
  ref?: string;
  phoneNumber?: string;
  comment?: string;
};

/** input object node */
export type createCateringService = {
  /** String object node */
  name: string;
};

/** input object node */
export type updateCateringService = {
  /** String object node */
  name: string;
  /** ID object node */
  id: string;
};

/** input object node */
export type removeCateringService = {
  /** ID object node */
  id: string;
};

/** input object node */
export type createServiceOption = {
  /** String object node */
  option: string;
  /** ID object node */
  cateringServiceID: string;
};

/** input object node */
export type removeServiceOption = {
  /** ID object node */
  id: string;
};

/** Route for drivers to follow */
export type Route = {
  /** String object node */
  startDateTime: string;
  /** ID object node */
  id: string;
  /** Describe is all deliveries delivered */
  ended?: boolean;
  /** ID object node */
  user?: string;
  deliveriesOrder?: string[]
};

/** input object node */
export type createRoute = {
  /** String object node */
  startDateTime: string;
  user: string;
  ended: boolean;
};

/** input object node */
export type updateRoute = {
  id: string;
  startDateTime?: string;
  user?: string;
  ended?: boolean;
  deliveriesOrder?: string[]
};

/** input object node */
export type removeRoute = {
  /** ID object node */
  id: string;
};

/** input object node */
export type updateServiceOption = {
  /** String object node */
  option: string;
  /** ID object node */
  id: string;
};

/** input object node */
export type createUserInput = {
  /** first name of the user */
  firstName: string;
  /** last name of the user */
  lastName: string;
};
