import * as React from "react";
import { Subscribe } from "unstated";
import { Catering } from "../Container";
import { Delivery, WeekDay, DayPart } from "../models";
import { Form } from "../forms/style";
import { Button, Spin } from "antd";

interface RouteDeliveriesState {
  suggestString: string;
  foundDeliveries: Delivery[];
}

interface RouteDeliveriesProps {
  addToRoute: (deliveryId: string) => void;
  removeFromRoute: (deliveryId: string) => void;
}

export class RouteDeliveriesList extends React.Component<
  RouteDeliveriesProps,
  RouteDeliveriesState
  > {
  state: RouteDeliveriesState = {
    suggestString: "",
    foundDeliveries: []
  };
  render() {
    return (
      <Subscribe to={[Catering]}>
        {(catering: typeof Catering) => {
          const routeList = catering.state.RouteDelivery!.list!
          const currentRoute = catering.state.Route!.list!.find(r => r.id === catering.state.currentRouteId)!
          if (currentRoute.deliveriesOrder) {
            routeList.sort((a, b) => {
              const positionA = currentRoute.deliveriesOrder!.findIndex(ra => ra === a.id)
              const positionB = currentRoute.deliveriesOrder!.findIndex(ra => ra === b.id)
              if (positionA === positionB) return 0
              return positionA > positionB ? 1 : -1
            })
          }
          return (
            <>
              <form className={Form}>
                <input
                  type="text"
                  value={this.state.suggestString}
                  onChange={e => {
                    const foundDeliveries = catering.state
                      .UnbindedDelivery!.list!.filter(d =>
                        d.adress
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      )
                      .filter(
                        d =>
                          !catering.state.RouteDelivery!.list!.find(
                            rd => rd.id === d.id
                          )
                      );
                    this.setState({
                      suggestString: e.target.value,
                      foundDeliveries
                    });
                  }}
                />
              </form>
              {catering.cruds!.Delivery.renderList(this.state.foundDeliveries, [
                {
                  title: "Dni tygodnia",
                  render: (text, { days }: Delivery) => (
                    <span>
                      {days!.map(d => (
                        <span key={d}>{WeekDay[d].slice(0, 2)},</span>
                      ))}
                    </span>
                  )
                },
                {
                  title: "Pora dnia",
                  render: (text, { dayPart }: Delivery) => (
                    <span>{DayPart[dayPart!]}</span>
                  )
                },
                {
                  title: "Pudełko",
                  render: (text, { serviceOptionID }: Delivery) => (
                    <span>
                      {
                        catering.state.ServiceOption!.list!.find(
                          so => so.id === serviceOptionID
                        )!.option
                      }
                    </span>
                  )
                },
                {
                  title: "",
                  render: (text, { id }: Delivery) => (
                    <span>
                      <a
                        onClick={() => {
                          this.props.addToRoute(id);
                        }}
                      >
                        Dodaj do trasy
                    </a>
                    </span>
                  )
                }
              ])}
              <div style={{ padding: "10px 0" }}>
                {catering.isFetching(`waypoints`) ? <Spin>Connecting to waypoints.foodeli</Spin> : (
                  <Button onClick={async () => {
                    const sortedDeliveries = await catering.sortCurrentRouteDeliveries()
                    console.log(sortedDeliveries)
                  }}>Sortuj algorytmem</Button>
                )}
              </div>
              {catering.cruds!.Delivery.renderList(
                routeList,
                [
                  {
                    title: "Dni tygodnia",
                    render: (text, { days }: Delivery) => (
                      <span>
                        {days!.map(d => (
                          <span key={d}>{WeekDay[d].slice(0, 2)},</span>
                        ))}
                      </span>
                    )
                  },
                  {
                    title: "Godzina od:",
                    render: (text, { hourFrom }: Delivery) => (
                      <span>
                        {hourFrom || 'brak'}
                      </span>
                    )
                  },
                  {
                    title: "Godzina do:",
                    render: (text, { hourTo }: Delivery) => (
                      <span>
                        {hourTo|| 'brak'}
                      </span>
                    )
                  },
                  {
                    title: "",
                    render: (text, { id }: Delivery) => (
                      <span>
                        <a
                          onClick={() => {
                            this.props.removeFromRoute(id);
                          }}
                        >
                          Usuń z trasy
                      </a>
                      </span>
                    )
                  }
                ]
              )}
            </>
          )
        }}
      </Subscribe>
    );
  }
}
