import * as React from "react";
import { Subscribe } from "unstated";
import { Catering } from "../Container";
import { Delivery, WeekDay, DayPart } from "../models";
export const DeliveryList = () => (
  <Subscribe to={[Catering]}>
    {(catering: typeof Catering) => (
      <>
        {catering.cruds!.Delivery.renderList(catering.state.Delivery!.list!, [
          {
            title: "Dni tygodnia",
            render: (text, { days }: Delivery) => (
              <span>
                {days!.map(d => (
                  <span key={d}>{WeekDay[d].slice(0, 2)},</span>
                ))}
              </span>
            )
          },
          {
            title: "Pora dnia",
            render: (text, { dayPart }: Delivery) => (
              <span>{DayPart[dayPart!]}</span>
            )
          },
          {
            title: "Pudełko",
            render: (text, { serviceOptionID }: Delivery) => (
              <span>
                {
                  catering.state.ServiceOption!.list!.find(
                    so => so.id === serviceOptionID
                  )!.option
                }
              </span>
            )
          },
          {
            title: "",
            render: (text, { id }: Delivery) => (
              <span>
                <a
                  onClick={() => {
                    catering
                      .cruds!.Delivery.remove({
                        id
                      })
                      .then(() => {
                        catering.setState({
                          Delivery: {
                            list: catering.state.Delivery!.list!.filter(
                              o => o.id !== id
                            )
                          }
                        });
                      });
                  }}
                >
                  Usuń
                </a>
              </span>
            )
          },
          {
            title: "",
            render: (text, { id }: Delivery) => (
              <span>
                <a
                  onClick={() => {
                    catering.setState({
                      view: "edit",
                      Delivery: {
                        ...catering.state.Delivery,
                        current: catering.state.Delivery!.list!.find(
                          cs => cs.id === id
                        )
                      }
                    });
                  }}
                >
                  Edytuj
                </a>
              </span>
            )
          }
        ])}
      </>
    )}
  </Subscribe>
);
