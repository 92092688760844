import * as React from "react";
import { createRoute, updateRoute } from "../models";
import { Form } from "./style";
import moment from "moment";
interface RouteFormProps {
  Route?: updateRoute;
  onCreate: (o: createRoute) => void;
  onUpdate: (o: updateRoute) => void;
}
export class RouteForm extends React.Component<RouteFormProps, createRoute> {
  formElement?: HTMLFormElement;
  state: createRoute = {
    startDateTime: "",
    user: "",
    ended: false
  };
  render() {
    return (
      <form
        className={Form}
        ref={ref => ref && (this.formElement = ref)}
        onSubmit={e => {
          e.stopPropagation();
          e.preventDefault();
          if (this.props.Route) {
            this.props.onUpdate({
              ...this.props.Route,
              ...this.state
            });
            return;
          }
          this.props.onCreate(this.state);
        }}
      >
        <input
          type="text"
          value={this.state.user}
          required
          onChange={e => {
            this.setState({
              user: e.target.value
            });
          }}
        />
        <label>Adres email kierowcy</label>
        <input
          type="date"
          value={this.state.startDateTime}
          required
          onChange={e => {
            this.setState({
              startDateTime: e.target.value
            });
          }}
        />
        <label>Data rozpoczęcia trasy</label>
        <input type="submit" value={this.props.Route ? "edytuj" : "dodaj"} />
      </form>
    );
  }
}
