import { Layout, Menu, Breadcrumb, Icon, Button } from "antd";
import * as React from "react";
import { Subscribe } from "unstated";
import { Catering } from "./Container";
import { ViewResolver } from "./ViewResolver";
const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

export class LayoutMain extends React.Component {
  state = {
    collapsed: false
  };
  onCollapse = (collapsed: boolean) => {
    this.setState({ collapsed });
  };

  render() {
    return (
      <Subscribe to={[Catering]}>
        {(catering: typeof Catering) => {
          if (!catering.state.token) {
            return (
              <Layout style={{ minHeight: "100vh" }}>
                <Layout>
                  <Content
                    style={{
                      margin: "0 16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column"
                    }}
                  >
                    <img
                      style={{
                        width: 200,
                        height: 200,
                        borderRadius: 100,
                        marginBottom:25
                      }}
                      src={require("./images/logo.png")}
                    />
                    <Button
                      type="primary"
                      size="large"
                      href="#"
                      onClick={() => catering.login()}
                    >
                      Cateringi
                    </Button>
                  </Content>
                  <Footer style={{ textAlign: "center" }}>
                    Foodeli Catering 2019
                  </Footer>
                </Layout>
              </Layout>
            );
          }
          let ContentView = ViewResolver(catering);
          return (
            <Layout style={{ minHeight: "100vh" }}>
              <Sider
                collapsible
                collapsed={this.state.collapsed}
                onCollapse={this.onCollapse}
              >
                <div className="logo" />
                <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
                  <SubMenu
                    key="sub1"
                    title={
                      <span>
                        <Icon type="desktop" />
                        <span>Firmy Cateringowe</span>
                      </span>
                    }
                  >
                    <Menu.Item
                      key="3"
                      onClick={() =>
                        catering.setState({
                          currentModel: "CateringService",
                          view: "list"
                        })
                      }
                    >
                      Lista
                    </Menu.Item>
                    <Menu.Item
                      key="4"
                      onClick={() =>
                        catering.setState({
                          currentModel: "CateringService",
                          view: "create"
                        })
                      }
                    >
                      Dodaj
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu
                    key="sub2"
                    title={
                      <span>
                        <Icon type="desktop" />
                        <span>Punkty dostaw</span>
                      </span>
                    }
                  >
                    <Menu.Item
                      key="5"
                      onClick={() =>
                        catering.setState({
                          currentModel: "Delivery",
                          view: "list"
                        })
                      }
                    >
                      Lista
                    </Menu.Item>
                    <Menu.Item
                      key="6"
                      onClick={() =>
                        catering.setState({
                          currentModel: "Delivery",
                          view: "create"
                        })
                      }
                    >
                      Dodaj
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu
                    key="sub3"
                    title={
                      <span>
                        <Icon type="desktop" />
                        <span>Trasy</span>
                      </span>
                    }
                  >
                    <Menu.Item
                      key="7"
                      onClick={() =>
                        catering.setState({
                          currentModel: "Route",
                          view: "list"
                        })
                      }
                    >
                      Lista
                    </Menu.Item>
                    <Menu.Item
                      key="8"
                      onClick={() =>
                        catering.setState({
                          currentModel: "Route",
                          view: "create"
                        })
                      }
                    >
                      Dodaj
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub4"
                    title={
                      <span>
                        <Icon type="desktop" />
                        <span>Dostarczone</span>
                      </span>
                    }
                  >
                    <Menu.Item
                      key="9"
                      onClick={() =>
                        catering.setState({
                          currentModel: "DoneDelivery",
                          view: "list"
                        })
                      }
                    >
                      Lista
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="11" onClick={() => catering.logout()}>
                    Wyloguj się
                  </Menu.Item>
                </Menu>
              </Sider>
              <Layout>
                <Content style={{ margin: "0 16px" }}>{ContentView}</Content>
                <Footer style={{ textAlign: "center" }}>
                  Foodeli Catering 2019
                </Footer>
              </Layout>
            </Layout>
          );
        }}
      </Subscribe>
    );
  }
}
