

export type CachedAddressType = {
  _id?: string;
  address: string;
  lat: number;
  lng: number;
}
const slothking: (host:string, fn:(url:string, params:{ props:{[x:string]:any}, method?: 'GET'|'POST'|'PATCH'|'DELETE'|'PUT' })=>Promise<any> ) => {
  foodeliWaypoints: {
    name: string;
    endpoints: {
      bestRoute: (params:{props:         {
          addresses?: string[];
        },method?: 'GET'|'POST'|'PATCH'|'DELETE'|'PUT'}) => Promise<        {
          addresses: string[];
        }>;
    };
  };
} = (host,fn) => ({
  foodeliWaypoints: {
    name: 'foodeliWaypoints',
    endpoints: {
      bestRoute: ({props,method='POST'}) => fn(`${host}foodeliWaypoints/bestRoute`,{
        props,
       method,
      }).then(res => res.json())
    }
  }
});
export type foodeliWaypointsResponseState = {
  bestRoute:   {
    addresses: string[];
  }
};
export type foodeliWaypointsExtensionState = {
  addresses: string[];
};

export default slothking;