import * as React from "react";
import { Subscribe } from "unstated";
import { Catering } from "../Container";
import { ServiceOption } from "../models";
export const ServiceOptionList = () => (
  <Subscribe to={[Catering]}>
    {(catering: typeof Catering) => (
      <>
        {catering.cruds!.ServiceOption.renderList(
          catering.state.ServiceOption!.list!,
          [
            {
              title: "",
              render: (text, { id }: ServiceOption) => (
                <span>
                  <a
                    onClick={() => {
                      catering
                        .cruds!.ServiceOption.remove({
                          id
                        })
                        .then(() => {
                          catering.setState({
                            ServiceOption: {
                              list: catering.state.ServiceOption!.list!.filter(
                                o => o.id !== id
                              )
                            }
                          });
                        });
                    }}
                  >
                    Usuń
                  </a>
                </span>
              )
            },
            {
              title: "",
              render: (text, { id }: ServiceOption) => (
                <span>
                  <a
                    onClick={() => {
                      catering.setState({
                        view: "edit",
                        ServiceOption: {
                          ...catering.state.ServiceOption,
                          current: catering.state.ServiceOption!.list!.find(
                            cs => cs.id === id
                          )
                        }
                      });
                    }}
                  >
                    Edytuj
                  </a>
                </span>
              )
            }
          ]
        )}
      </>
    )}
  </Subscribe>
);
