import * as React from "react";
import { Subscribe } from "unstated";
import { Catering } from "../Container";
import { DoneDelivery, WeekDay, DayPart } from "../models";
export const DoneDeliveryList = () => (
  <Subscribe to={[Catering]}>
    {(catering: typeof Catering) => (
      <>
        {catering.cruds!.DoneDelivery.renderList(
          catering.state.DoneDelivery!.list!,
          [
            {
              title: "Adres dostawy",
              render: (text, { deliveryID }: DoneDelivery) => {
                const delivery = catering.state.Delivery!.list!.find(
                  ({ id }) => id === deliveryID
                )!;
                return <span>{`${delivery.adress}`}</span>;
              }
            },
            {
              title: "Miniatura",
              render: (
                text,
                { photoURL, returnedPhotoURL, id }: DoneDelivery
              ) => {
                if (returnedPhotoURL) {
                  return (
                    <a href={returnedPhotoURL} target="_blank">
                      <img width={100} height={100} src={returnedPhotoURL} />
                    </a>
                  );
                }
                return (
                  <a
                    onClick={() => {
                      catering.getPhotoURL(id);
                    }}
                  >
                    Zobacz zdjęcie
                  </a>
                );
              }
            }
          ]
        )}
      </>
    )}
  </Subscribe>
);
