import api from "./api";

export const WAYPOINTS_HOST = process.env.REACT_APP_WAYPOINTS_HOST!
export const WaypointsApi = api(WAYPOINTS_HOST, async (url, params) => {
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(params.props),
        headers:{
            'Content-Type':'application/json'
        }
    })
})