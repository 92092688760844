import { Container } from "unstated";
import * as models from "./models";
import { cruds } from "./cruds";
import { WebAuth } from "auth0-js";
import { Api } from "./api";
import { WaypointsApi } from "./waypoints";

type BackendModels =
  | "CateringService"
  | "Delivery"
  | "Route"
  | "ServiceOption"
  | "RouteDelivery"
  | "DoneDelivery";
type CRUDViews = "create" | "edit" | "delete" | "list";


export const API_HOST = process.env.REACT_APP_API_HOST!

export const redirectUri = process.env.REACT_APP_HOSTNAME!

const auth = new WebAuth({
  audience: "https://foodeli.pl/",
  clientID: "FDR0PQpwhbVjz7NDMHOnRd60sU5qCSU1",
  domain: "foodeli.eu.auth0.com",
  responseType: "id_token",
  redirectUri,
  scope: "openid profile"
});

const prefix = (k: string) => `FoodeliCatering-${k}`;
const ls = {
  get: (key: string) => window.localStorage.getItem(prefix(key)),
  set: (key: string, value: string) =>
    window.localStorage.setItem(prefix(key), value)
};

export interface CateringState {
  view?: CRUDViews;
  currentModel?: BackendModels;
  token?: string;
  expire?: number;
  isFetching?: string[];
  user?: {
    username: string;
    id: string;
  };
  currentCateringServiceId?: string;
  currentRouteId?: string;
  CateringService?: {
    list?: models.CateringService[];
    current?: models.CateringService;
  };
  DoneDelivery?: {
    list?: models.DoneDelivery[];
    current?: models.DoneDelivery;
  };
  Delivery?: {
    list?: models.Delivery[];
    current?: models.Delivery;
  };
  RouteDelivery?: {
    list?: models.Delivery[];
  };
  UnbindedDelivery?: {
    list?: models.Delivery[];
  };
  Route?: {
    list?: models.Route[];
    current?: models.Route;
  };
  ServiceOption?: {
    list?: models.ServiceOption[];
    current?: models.ServiceOption;
  };
}

export type SerializedCloudState = Pick<
  CateringState,
  "user" | "token" | "expire"
>;

export class CateringContainer extends Container<CateringState> {
  cruds?: ReturnType<typeof cruds>;
  api?: Api;
  constructor() {
    super();
    this.state = {};
    this.onMount();
  }
  onMount = async () => {
    await this.cloudToState();
    console.log(this.state);
    if (!this.state.expire) {
      return this.setState({
        token: undefined
      });
    }
    const dateExp =
      new Date(0).setUTCSeconds(this.state.expire) - new Date().valueOf();
    if (dateExp < 0) {
      return this.setState({
        token: undefined
      });
    }
    if (!this.state.token) return;
    return this.onLogin();
  };
  onLogin = async () => {
    if (this.state.token) {
      this.api = new Api(API_HOST, this.state.token);
      this.cruds = cruds(this.api)!;
      this.loadLists();
    }
  };
  setCloud = () => {
    ls.set(
      "storage",
      JSON.stringify({
        user: this.state.user,
        token: this.state.token,
        expire: this.state.expire
      } as SerializedCloudState)
    );
  };
  clearCloud = () =>
    ls.set(
      "storage",
      JSON.stringify({
        user: undefined,
        token: undefined,
        expire: undefined
      } as SerializedCloudState)
    );
  cloudToState = () => {
    return this.setState(JSON.parse(ls.get("storage") || "{}"));
  };
  login = () => auth.authorize();
  sortCurrentRouteDeliveries = async () => {
    const message = `waypoints`
    const newSortedList = [...this.state.RouteDelivery!.list!]
    await this.setFetching(message)
    const result = await WaypointsApi.foodeliWaypoints.endpoints.bestRoute({
      props: {
        addresses: this.state.RouteDelivery!.list!.map(rd => rd.adress)
      }
    })
    newSortedList.sort((a, b) => {
      const positionA = result.addresses.findIndex(ra => ra === a.adress)
      const positionB = result.addresses.findIndex(ra => ra === b.adress)
      if (positionA === positionB) return 0
      return positionA > positionB ? 1 : -1
    })
    await this.cruds!.Route.update({
      id: this.state.currentRouteId!,
      deliveriesOrder: newSortedList.map(sl => sl.id),
    })
    await this.setState({
      RouteDelivery: {
        list: newSortedList
      }
    })
    await this.unsetFetching(message)
    return newSortedList
  }
  getPhotoURL = async (doneDeliveryID: string) => {
    const returnedPhotoURL = await this.api!.customGET(
      "done-delivery/photo-url-for/",
      doneDeliveryID
    );
    await this.setState(state => ({
      DoneDelivery: {
        list: state.DoneDelivery!.list!.map(dd =>
          dd.id === doneDeliveryID
            ? {
              ...dd,
              returnedPhotoURL
            }
            : dd
        )
      }
    }));
  };
  setFetching = (message: string) => this.setState({
    isFetching: [...(this.state.isFetching || []), message]
  })
  unsetFetching = (message: string) => this.setState({
    isFetching: (this.state.isFetching || []).filter(m => m !== message)
  })
  isFetching = (message: string) => (this.state.isFetching || []).includes(message)
  setToken = () =>
    new Promise(resolve => {
      auth.parseHash(async (error, result) => {
        console.log(error, result);
        if (error) {
          auth.logout({});
          return;
        }
        if (
          result &&
          result.idToken &&
          result.idTokenPayload &&
          result.idTokenPayload.sub &&
          result.idTokenPayload.exp &&
          result.idTokenPayload.nickname
        ) {
          await this.setState({
            token: result.idToken,
            expire: result.idTokenPayload.exp,
            user: {
              username: result.idTokenPayload.nickname,
              id: result.idTokenPayload.sub
            }
          })
            .then(this.setCloud)
            .then(async () => {
              window.location.href = "/";
              resolve();
            });
        }
      });
    });
  logout = async () => {
    await this.clearCloud();
    auth.logout({});
  };
  loadLists = async () => {
    const [
      CateringServiceList,
      DeliveryList,
      RouteList,
      ServiceOptionList,
      DoneDeliveryList
    ] = await Promise.all([
      this.cruds!.CateringService.list(),
      this.cruds!.Delivery.list(),
      this.cruds!.Route.list(),
      this.cruds!.ServiceOption.list(),
      this.cruds!.DoneDelivery.list()
    ]);
    await this.setState({
      CateringService: {
        list: CateringServiceList
      },
      Delivery: {
        list: DeliveryList
      },
      Route: {
        list: RouteList
      },
      ServiceOption: {
        list: ServiceOptionList
      },
      DoneDelivery: {
        list: DoneDeliveryList
      }
    });
  };
}

export const Catering = new CateringContainer();
