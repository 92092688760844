import { Table } from "antd";
import * as React from "react";
import { Api } from "./api";
import { ColumnProps } from "antd/lib/table";

export interface CRUDProps<
  O,
  C,
  U extends { id: string },
  R extends { id: string }
  > {
  create: (o: C) => Promise<O>;
  update: (o: U) => Promise<O>;
  remove: (o: R) => Promise<void>;
  list: () => Promise<O[]>;
  get: (id: string) => Promise<O>;
}

export class Crudder<
  O extends { id: string },
  C,
  U extends { id: string },
  R extends { id: string }
  > implements CRUDProps<O, C, U, R> {
  constructor(
    private api: Api,
    private model: string,
    private dataProps: {
      display: string;
      name: string;
    }[],
    private createForm?: React.Component<{ onSubmit: (c: C) => O }>
  ) { }
  public list = () => this.api.list(this.model) as Promise<O[]>;
  public customList = async (prefix: string) => {
    const response = await this.api.f(prefix, "GET");
    const results = (await response.json()) as Promise<O[]>;
    return results;
  };
  public customPost = async (prefix: string) => {
    const response = await this.api.f(prefix, "POST");
    return response;
  };
  public customDelete = async (prefix: string) => {
    const response = await this.api.f(prefix, "DELETE");
    return response;
  };
  public create = (params: C) =>
    this.api.create(this.model, params) as Promise<O>;
  public update = (params: U) =>
    this.api.update(this.model, params) as Promise<O>;
  public remove = (params: R) =>
    this.api.remove(this.model, params) as Promise<void>;
  public get = (id: string) => this.api.getById(this.model, id) as Promise<O>;
  public renderList = (
    listObjects: O[],
    additionalItems: ColumnProps<O>[] = [],
    pagination?: boolean
  ) => {
    return (
      <>
        <Table
          rowKey={"id"}
          dataSource={listObjects}
          pagination={pagination ? undefined : false}
          columns={[
            ...this.dataProps.map(d => ({
              title: d.display,
              dataIndex: d.name,
              key: d.name
            })),
            ...additionalItems
          ]}
        />
      </>
    );
  };
  public renderEditForm = (o?: O) => { };
}
