import * as React from "react";
import { createCateringService, updateCateringService } from "../models";
import { Form } from "./style";
interface CateringServiceFormProps {
  cateringService?: updateCateringService;
  onCreate: (o: createCateringService) => void;
  onUpdate: (o: updateCateringService) => void;
}
export class CateringServiceForm extends React.Component<
  CateringServiceFormProps,
  createCateringService
> {
  formElement?: HTMLFormElement;
  state: createCateringService = {
    name: this.props.cateringService ? this.props.cateringService.name : ""
  };
  render() {
    return (
      <form
        className={Form}
        ref={ref => ref && (this.formElement = ref)}
        onSubmit={e => {
          e.stopPropagation();
          e.preventDefault();
          if (this.props.cateringService) {
            this.props.onUpdate({
              ...this.props.cateringService,
              ...this.state
            });
            return;
          }
          this.props.onCreate(this.state);
        }}
      >
        <input
          type="text"
          value={this.state.name}
          required
          onChange={e => {
            this.setState({
              name: e.target.value
            });
          }}
        />
        <label>Nazwa firmy cateringowej</label>
        <input
          type="submit"
          value={this.props.cateringService ? "edytuj" : "dodaj"}
        />
      </form>
    );
  }
}
