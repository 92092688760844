export type suggestParams = {
  language: string;
  components: string;
  location: string;
  radius: number;
  strictbounds: boolean;
};

export type suggestProps = {
  keyword?: string;
  suggestParams?: Partial<suggestParams>;
};

const DEV_API_HOST = "https://foodeli-dev.aexol.com/foodeli/suggestAddress";
const PRODUCTION_API_HOST =
  "https://foodeli-sloth.aexol.com/foodeli/suggestAddress";
const SUGGEST_API_HOST =
  process.env.NODE_ENV === "development" ? DEV_API_HOST : PRODUCTION_API_HOST;

export const geoSuggest = async (keyword: string) => {
  const response = await fetch(SUGGEST_API_HOST, {
    method: "POST",
    headers:{
      "Content-Type":"application/json"
    },
    body: JSON.stringify({
      keyword,
      suggestParams: {
        language: "pl",
        components: "country:pl",
        location: "53.1276047, 23.0860267",
        radius: 50000,
        strictbounds: true
      }
    } as suggestProps)
  });
  const json = await response.json();
  return json.suggestedAddress as string[];
};
