import { Crudder } from "./CRUDList";
import * as React from "react";
import {
  CateringService,
  createCateringService,
  updateCateringService,
  removeCateringService,
  ServiceOption,
  createServiceOption,
  updateServiceOption,
  removeServiceOption,
  Delivery,
  createDelivery,
  updateDelivery,
  removeDelivery,
  Route,
  createRoute,
  updateRoute,
  removeRoute,
  DoneDelivery,
  createDoneDelivery,
  updateDoneDelivery,
  removeDoneDelivery
} from "./models";
import { Api } from "./api";

export const cruds = (api: Api) => ({
  CateringService: new Crudder<
    CateringService,
    createCateringService,
    updateCateringService,
    removeCateringService
  >(api, "catering-service", [
    {
      name: "name",
      display: "Nazwa firmy"
    }
  ]),
  ServiceOption: new Crudder<
    ServiceOption,
    createServiceOption,
    updateServiceOption,
    removeServiceOption
  >(api, "service-option", [
    {
      name: "option",
      display: "Rodzaj diety"
    },
    {
      name: "service",
      display: "Id firmy cateringowej"
    }
  ]),
  Delivery: new Crudder<
    Delivery,
    createDelivery,
    updateDelivery,
    removeDelivery
  >(api, "delivery", [
    {
      name: "adress",
      display: "Adres"
    }
  ]),
  DoneDelivery: new Crudder<
    DoneDelivery,
    createDoneDelivery,
    updateDoneDelivery,
    removeDoneDelivery
  >(api, "done-delivery", [
    {
      name: "user",
      display: "Użytkownik"
    }
  ]),
  Route: new Crudder<Route, createRoute, updateRoute, removeRoute>(
    api,
    "route",
    [
      {
        name: "user",
        display: "user"
      },
      {
        name: "startDateTime",
        display: "Data"
      }
    ]
  )
});
