import * as React from "react";
import { Subscribe } from "unstated";
import { Catering } from "../Container";
import { CateringService } from "../models";
export const CateringServiceList = () => (
  <Subscribe to={[Catering]}>
    {(catering: typeof Catering) => (
      <>
        {catering.cruds!.CateringService.renderList(
          catering.state.CateringService!.list!,
          [
            {
              title: "",
              key:"usun",
              render: (text, { id }: CateringService) => (
                <span>
                  <a
                    onClick={() => {
                      catering
                        .cruds!.CateringService.remove({
                          id
                        })
                        .then(() => {
                          catering.setState({
                            CateringService: {
                              list: catering.state.CateringService!.list!.filter(
                                o => o.id !== id
                              )
                            }
                          });
                        });
                    }}
                  >
                    Usuń
                  </a>
                </span>
              )
            },
            {
              title: "",
              key:"edytuj",
              render: (text, { id }: CateringService) => (
                <span>
                  <a
                    onClick={() => {
                      catering.setState({
                        view: "edit",
                        CateringService: {
                          ...catering.state.CateringService,
                          current: catering.state.CateringService!.list!.find(
                            cs => cs.id === id
                          )
                        }
                      });
                    }}
                  >
                    Edytuj
                  </a>
                </span>
              )
            },
            {
              title: "",
              key:"opcjepudelek",
              render: (text, { id }: CateringService) => (
                <span>
                  <a
                    onClick={async () => {
                      const listedServiceOptions = await catering.cruds!.ServiceOption.customList(
                        `catering-service/service-options/${id}`
                      );
                      catering.setState({
                        currentCateringServiceId: id,
                        currentModel: "ServiceOption",
                        view: "list",
                        ServiceOption: {
                          list: listedServiceOptions
                        }
                      });
                    }}
                  >
                    Opcje Pudełek
                  </a>
                </span>
              )
            },
            {
              title: "",
              key:"dodajpudelko",
              render: (text, { id }: CateringService) => (
                <span>
                  <a
                    onClick={async () => {
                      catering.setState({
                        currentCateringServiceId: id,
                        currentModel: "ServiceOption",
                        view: "create"
                      });
                    }}
                  >
                    Dodaj pudełko
                  </a>
                </span>
              )
            }
          ]
        )}
      </>
    )}
  </Subscribe>
);
