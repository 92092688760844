import { style } from "typestyle";
import { Colors } from "../../Colors";

export const Form = style({
  display: "flex",
  flexFlow: "column nowrap",
  $nest: {
    [`input[type="text"]`]: {
      border: 0,
      background: "transparent",
      padding: `25px 0px 5px`,
      borderBottomColor: Colors.blue[0],
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      outline:`0 !important`
    },
    [`input[type="submit"]`]: {
        marginTop:25,
        background:Colors.blue[0],
        color:Colors.grey[0],
        border:0,
        padding:`10px 24px`,
        alignSelf:"flex-end",
        outline:`0 !important`
    },
    label:{
      fontSize:11,
      color:Colors.grey[8],
      marginBottom:15
    },
    ul:{
      listStyle:"none",
      padding:0,
      $nest:{
        li:{
          borderBottomColor: Colors.blue[3],
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          padding: `10px 0px 2px`,
          cursor:'pointer',
          $nest:{
            "&:hover":{
              color:Colors.blue[0],
              fontWeight:'bold',
              borderColor:Colors.blue[0]
            }
          }
        }
      }
    }
  }
});
