import * as React from "react";
import { createServiceOption, updateServiceOption } from "../models";
import { Form } from "./style";
interface ServiceOptionFormProps {
  cateringServiceID: string;
  ServiceOption?: updateServiceOption;
  onCreate: (o: createServiceOption) => void;
  onUpdate: (o: updateServiceOption) => void;
}
export class ServiceOptionForm extends React.Component<
  ServiceOptionFormProps,
  createServiceOption
> {
  formElement?: HTMLFormElement;
  state: createServiceOption = {
    cateringServiceID: this.props.cateringServiceID,
    option: this.props.ServiceOption ? this.props.ServiceOption.option : ""
  };
  render() {
    return (
      <form
        className={Form}
        ref={ref => ref && (this.formElement = ref)}
        onSubmit={e => {
          e.stopPropagation();
          e.preventDefault();
          if (this.props.ServiceOption) {
            this.props.onUpdate({
              ...this.props.ServiceOption,
              ...this.state
            });
            return;
          }
          this.props.onCreate(this.state);
        }}
      >
        <input
          type="text"
          value={this.state.option}
          required
          onChange={e => {
            this.setState({
              option: e.target.value
            });
          }}
        />
        <label>Nazwa rodzaju pudełka</label>
        <input
          type="submit"
          value={this.props.ServiceOption ? "edytuj" : "dodaj"}
        />
      </form>
    );
  }
}
