import * as React from "react";
import { Catering } from "./Container";
import {
  CateringServiceList,
  DeliveryList,
  RouteList,
  ServiceOptionList,
  RouteDeliveriesList,
  DoneDeliveryList
} from "./lists";
import {
  CateringServiceForm,
  ServiceOptionForm,
  RouteForm,
  DeliveryForm
} from "./forms";
import moment from "moment";
import { createDelivery, WeekDay } from "./models";
export const ViewResolver = (catering: typeof Catering) => {
  const { state } = catering;
  if (state.view === "list") {
    if (
      state.currentModel === "CateringService" &&
      state.CateringService &&
      state.CateringService.list
    ) {
      return <CateringServiceList />;
    }
    if (
      state.currentModel === "Delivery" &&
      state.Delivery &&
      state.Delivery.list
    ) {
      return <DeliveryList />;
    }
    if (
      state.currentModel === "DoneDelivery" &&
      state.DoneDelivery &&
      state.DoneDelivery.list
    ) {
      return <DoneDeliveryList />;
    }
    if (state.currentModel === "Route" && state.Route && state.Route.list) {
      return <RouteList />;
    }
    if (
      state.currentModel === "ServiceOption" &&
      state.currentCateringServiceId &&
      state.ServiceOption &&
      state.ServiceOption.list
    ) {
      return <ServiceOptionList />;
    }

    if (
      state.currentModel === "RouteDelivery" &&
      state.Delivery &&
      state.currentRouteId
    ) {
      return (
        <RouteDeliveriesList
          addToRoute={async deliveryId => {
            await catering.cruds!.Delivery.customPost(
              `route/${state.currentRouteId}/add-delivery/${deliveryId}/`
            );
            await catering.setState(state => ({
              RouteDelivery: {
                list: [
                  ...state.RouteDelivery!.list!,
                  state.Delivery!.list!.find(d => d.id === deliveryId)!
                ]
              }
            }));
          }}
          removeFromRoute={async deliveryId => {
            await catering.cruds!.Delivery.customDelete(
              `route/${state.currentRouteId}/remove-delivery/${deliveryId}/`
            );
            await catering.setState(state => ({
              RouteDelivery: {
                list: state.RouteDelivery!.list!.filter(
                  rd => rd.id !== deliveryId
                )
              }
            }));
          }}
        />
      );
    }
  }
  if (state.view === "create" || state.view === "edit") {
    if (state.currentModel === "CateringService" && state.CateringService) {
      return (
        <CateringServiceForm
          cateringService={
            state.view === "edit" ? state.CateringService.current : undefined
          }
          onCreate={async o => {
            const createdObject = await catering.cruds!.CateringService.create(
              o
            );
            await catering.setState({
              view: "list",
              currentModel: "CateringService",
              CateringService: {
                list: [...catering.state.CateringService!.list!, createdObject]
              }
            });
          }}
          onUpdate={async o => {
            const updatedObject = await catering.cruds!.CateringService.update(
              o
            );
            await catering.setState({
              view: "list",
              currentModel: "CateringService",
              CateringService: {
                list: catering.state.CateringService!.list!.map(ob =>
                  ob.id === o.id ? updatedObject : ob
                )
              }
            });
          }}
        />
      );
    }
    if (state.currentModel === "Route" && state.Route) {
      return (
        <RouteForm
          Route={state.view === "edit" ? state.Route.current : undefined}
          onCreate={async o => {
            const createdObject = await catering.cruds!.Route.create({
              ...o,
              startDateTime: moment(o.startDateTime).format(
                "YYYY-MM-DD[T]HH:mm:ss[Z]"
              ),
              ended: false
            });
            await catering.setState({
              view: "list",
              currentModel: "Route",
              Route: {
                list: [...catering.state.Route!.list!, createdObject]
              }
            });
          }}
          onUpdate={async o => {
            const updatedObject = await catering.cruds!.Route.update({
              ...o,
              startDateTime: moment(o.startDateTime).format(
                "YYYY-MM-DD[T]HH:mm:ss[Z]"
              ),
              ended: false
            });
            await catering.setState({
              view: "list",
              currentModel: "Route",
              Route: {
                list: catering.state.Route!.list!.map(ob =>
                  ob.id === o.id ? updatedObject : ob
                )
              }
            });
          }}
        />
      );
    }
    if (
      state.currentModel === "ServiceOption" &&
      state.currentCateringServiceId
    ) {
      return (
        <ServiceOptionForm
          cateringServiceID={state.currentCateringServiceId}
          ServiceOption={
            state.view === "edit" ? state.ServiceOption!.current : undefined
          }
          onCreate={async o => {
            const createdObject = await catering.cruds!.ServiceOption.create(o);
            await catering.setState({
              view: "list",
              currentModel: "ServiceOption",
              ServiceOption: {
                list: [...catering.state.ServiceOption!.list!, createdObject]
              }
            });
          }}
          onUpdate={async o => {
            const updatedObject = await catering.cruds!.ServiceOption.update(o);
            await catering.setState({
              view: "list",
              currentModel: "ServiceOption",
              ServiceOption: {
                list: catering.state.ServiceOption!.list!.map(ob =>
                  ob.id === o.id ? updatedObject : ob
                )
              }
            });
          }}
        />
      );
    }
    if (state.currentModel === "Delivery" && state.Delivery) {
      return (
        <DeliveryForm
          Delivery={state.view === "edit" ? state.Delivery.current : undefined}
          onCreate={async o => {
            const createdObject = await catering.cruds!.Delivery.create({
              ...o
            });
            await catering.setState({
              view: "list",
              currentModel: "Delivery",
              Delivery: {
                list: [...catering.state.Delivery!.list!, createdObject]
              }
            });
          }}
          onUpdate={async o => {
            const updatedObject = await catering.cruds!.Delivery.update(o);
            await catering.setState({
              view: "list",
              currentModel: "Delivery",
              Delivery: {
                list: catering.state.Delivery!.list!.map(ob =>
                  ob.id === o.id ? updatedObject : ob
                )
              }
            });
          }}
        />
      );
    }
  }
  return <div>No view</div>;
};
