export class Api {
  constructor(private host: string, private token: string) {}
  f = (
    url: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    body?: Record<string, any>
  ) => {
    if (body) {
      return fetch([this.host, url].join("/"), {
        method,
        body: JSON.stringify(body),
        headers: new Headers({
          Authorization: `Bearer ${this.token}`,
          "Content-Type": "application/json"
        })
      });
    }
    return fetch([this.host, url].join("/"), {
      method,
      headers: new Headers({
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json"
      })
    });
  };
  create = async (model: string, body: Record<string, any>) => {
    const response = await this.f(model, "PUT", body);
    return response.json();
  };
  update = async <T extends { id: string }>(model: string, body: T) => {
    const { id, ...updateBody } = body;
    const response = await this.f([model, id].join("/"), "POST", updateBody);
    return response.json();
  };
  remove = async <T extends { id: string }>(model: string, body: T) => {
    const { id, ...removeBody } = body;
    await this.f([model, id].join("/"), "DELETE");
    return;
  };
  list = async (model: string) => {
    const response = await this.f(`${model}/all`, "GET");
    return response.json();
  };
  customGET = async (model: string, id: string) => {
    const response = await this.f([model, id].join("/"), "GET");
    return response.text();
  };
  getById = async (model: string, id: string) => {
    const response = await this.f([model, id].join("/"), "GET");
    return response.json();
  };
}
export { geoSuggest } from "./suggest";
