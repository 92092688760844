import * as React from "react";
import { Subscribe } from "unstated";
import { Catering } from "../Container";
import { Route } from "../models";
export const RouteList = () => (
  <Subscribe to={[Catering]}>
    {(catering: typeof Catering) => (
      <>
        {catering.cruds!.Route.renderList(catering.state.Route!.list!, [
          {
            title: "",
            render: (text, { id }: Route) => (
              <span>
                <a
                  onClick={() => {
                    catering
                      .cruds!.Route.remove({
                        id
                      })
                      .then(() => {
                        catering.setState({
                          Route: {
                            list: catering.state.Route!.list!.filter(
                              o => o.id !== id
                            )
                          }
                        });
                      });
                  }}
                >
                  Usuń
                </a>
              </span>
            )
          },
          {
            title: "",
            render: (text, { id }: Route) => (
              <span>
                <a
                  onClick={() => {
                    catering.setState({
                      view: "edit",
                      Route: {
                        ...catering.state.Route,
                        current: catering.state.Route!.list!.find(
                          cs => cs.id === id
                        )
                      }
                    });
                  }}
                >
                  Edytuj
                </a>
              </span>
            )
          },
          {
            title: "",
            render: (text, { id }: Route) => (
              <span>
                <a
                  onClick={async () => {
                    const listedDeliveries = await catering.cruds!.Delivery.customList(
                      `route/${id}/deliveries`
                    );
                    const availableDeliveries = await catering.cruds!.Delivery.customList(
                      `delivery/all-unbinded`
                    );
                    catering.setState({
                      currentRouteId: id,
                      currentModel: "RouteDelivery",
                      view: "list",
                      UnbindedDelivery: {
                        list: availableDeliveries
                      },
                      RouteDelivery: {
                        list: listedDeliveries
                      }
                    });
                  }}
                >
                  Punkty dostaw
                </a>
              </span>
            )
          }
        ])}
      </>
    )}
  </Subscribe>
);
