import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import "antd/dist/antd.css";
import { LayoutMain } from "./Layout";
import { Provider } from "unstated";
import { Catering } from "./Container";
class App extends Component {
  componentDidMount() {
    Catering.setToken();
  }
  render() {
    return (
      <Provider inject={[Catering]}>
        <LayoutMain />
      </Provider>
    );
  }
}

export default App;
