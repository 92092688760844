import * as React from "react";
import {
  createDelivery,
  updateDelivery,
  DayPart,
  WeekDay,
  Delivery
} from "../models";
import { Form } from "./style";
import { geoSuggest } from "../api";

import { Subscribe } from "unstated";
import { Catering } from "../Container";

interface DeliveryFormProps {
  Delivery?: Delivery;
  onCreate: (o: createDelivery) => void;
  onUpdate: (o: updateDelivery) => void;
}
interface DeliveryFormState extends createDelivery {
  cateringServiceID: string;
  suggestString: string;
  suggestValues: string[];
}
export class DeliveryForm extends React.Component<
  DeliveryFormProps,
  DeliveryFormState
  > {
  formElement?: HTMLFormElement;
  state: DeliveryFormState = {
    adress: "",
    customHour: undefined,
    suggestString: this.props.Delivery ? this.props.Delivery.adress : "",
    dayPart: DayPart.Rano,
    days: [
      WeekDay.Poniedziałek,
      WeekDay.Wtorek,
      WeekDay.Środa,
      WeekDay.Czwartek,
      WeekDay.Piątek
    ],
    serviceOptionID: "",
    cateringServiceID: "",
    suggestValues: [],
    ...(this.props.Delivery || {})
  };
  componentDidMount() {
    if (
      !this.state.cateringServiceID &&
      this.props.Delivery &&
      this.props.Delivery.serviceOptionID
    ) {
      this.setState({
        cateringServiceID: Catering.state.ServiceOption!.list!.find(
          so => so.id === this.props.Delivery!.serviceOptionID
        )!.cateringServiceID
      });
    }
  }
  render() {
    console.log(this.state)
    return (
      <Subscribe to={[Catering]}>
        {(catering: typeof Catering) => {
          return (
            <form
              className={Form}
              ref={ref => ref && (this.formElement = ref)}
              onSubmit={e => {
                e.stopPropagation();
                e.preventDefault();
                const {
                  suggestString,
                  suggestValues,
                  cateringServiceID,
                  ...wantedValues
                } = this.state;
                if (this.props.Delivery) {
                  this.props.onUpdate({
                    ...this.props.Delivery,
                    ...wantedValues
                  });
                  return;
                }
                this.props.onCreate(wantedValues);
              }}
            >
              <input
                type="text"
                value={this.state.suggestString}
                required
                onChange={e => {
                  const suggestString = e.target.value;
                  this.setState({
                    suggestString
                  });
                  if (e.target.value.length > 1) {
                    geoSuggest(suggestString).then(suggestValues => {
                      this.setState({
                        suggestValues
                      });
                    });
                  }
                }}
              />
              <label>Adres</label>
              {this.state.suggestValues.length > 0 && (
                <>
                  <ul>
                    {this.state.suggestValues.map(sv => (
                      <li
                        key={sv}
                        onClick={() => {
                          this.setState({
                            adress: sv,
                            suggestString: sv,
                            suggestValues: []
                          });
                        }}
                      >
                        {sv}
                      </li>
                    ))}
                  </ul>
                  <label>Wybierz adres</label>
                </>
              )}
              <select
                multiple
                required
                onChange={e => {
                  const days = [...e.target.selectedOptions]
                    .filter(o => o.selected)
                    .map(o => parseInt(o.value as any));
                  this.setState({
                    days
                  });
                }}
                value={this.state.days.map(d => d.toString())}
              >
                {Object.keys(WeekDay).map((k, i) => (
                  <option key={i} value={i}>
                    {WeekDay[i]}
                  </option>
                ))}
              </select>
              <label>Dni w których pudełka mają być dostarczone</label>
              <select
                required
                onChange={e => {
                  this.setState({
                    dayPart: parseInt(e.target.value)
                  });
                }}
                value={this.state.dayPart}
              >
                {Object.keys(DayPart).map((k, i) => (
                  <option key={i} value={i}>
                    {DayPart[i]}
                  </option>
                ))}
              </select>
              <label>Część dnia</label>
              <select
                required
                onChange={async e => {
                  if (!e.target.value) return;
                  const id = e.target.value;
                  const listedServiceOptions = await catering.cruds!.ServiceOption.customList(
                    `catering-service/service-options/${id}`
                  );
                  await catering.setState({
                    ServiceOption: {
                      list: listedServiceOptions
                    }
                  });
                  this.setState({
                    cateringServiceID: id
                  });
                }}
                value={this.state.cateringServiceID}
              >
                <option value={""}> -- select an option -- </option>
                {catering.state.CateringService!.list!.map(cs => (
                  <option key={cs.id} value={cs.id}>
                    {cs.name}
                  </option>
                ))}
              </select>
              <label>Firma kateringowa</label>
              {this.state.cateringServiceID && (
                <>
                  <select
                    required
                    onChange={e => {
                      if (!e.target.value) return;
                      this.setState({
                        serviceOptionID: e.target.value
                      });
                    }}
                    value={this.state.serviceOptionID}
                  >
                    <option disabled value={""}>
                      {" "}
                      -- select an option --{" "}
                    </option>
                    {catering.state.ServiceOption!.list!.map(cs => (
                      <option key={cs.id} value={cs.id}>
                        {cs.option}
                      </option>
                    ))}
                  </select>
                  <label>Rodzaj pudełka</label>
                </>
              )}
              <input
                type="time"
                value={this.state.hourFrom}
                onChange={e => {
                  this.setState({
                    hourFrom: e.target.value
                  });
                }}
              />
              <label>Dostarczyć od godziny</label>
              <input
                type="time"
                value={this.state.hourTo}
                onChange={e => {
                  this.setState({
                    hourTo: e.target.value
                  });
                }}
              />
              <label>Dostarczyć do godziny</label>
              <input
                type="text"
                value={this.state.comment}
                onChange={e => {
                  this.setState({
                    comment: e.target.value
                  });
                }}
              />
              <label>Uwagi</label>
              <input
                type="text"
                value={this.state.ref}
                required
                onChange={e => {
                  this.setState({
                    ref: e.target.value
                  });
                }}
              />
              <label>Nr referencyjny paczki</label>
              <input
                type="text"
                value={this.state.phoneNumber}
                required
                onChange={e => {
                  this.setState({
                    phoneNumber: e.target.value
                  });
                }}
              />
              <label>Nr telefonu klienta</label>
              <input
                type="submit"
                value={this.props.Delivery ? "edytuj" : "dodaj"}
              />
            </form>
          );
        }}
      </Subscribe>
    );
  }
}
